import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row
} from 'reactstrap'
import { useState } from 'react'

export const Contact = ({
  updateError,
  handleSend,
  handleDetails,
  contact,
}) =>{

  const [contactErrors, setContactErrors] = useState({})
  const send = () => {
    setContactErrors({})
    if(validated()){
      handleSend()
    }
  }

  const validated = () => {
    let errors = {};
    if(!contact.name){
      errors.name = true
    }
    if(!contact.phone){
      errors.phone = true
    }
    if(!contact.consented){
      errors.consented = true
    }
    if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(contact.email) === false){
      errors.email = true
    }
    setContactErrors(errors)
    return Object.keys(errors).length === 0
  }

  return (
    <Card>
      <CardBody>
        <div className={'d-flex justify-content-center'}>
          <p className='mb-3 text-dark normal-text'>We're comparing leased line prices for your site now.</p>
        </div>
        <div className={'d-flex justify-content-center'}>
          <p className='text-dark normal-text'>How can our leased line experts contact you to discuss the results?</p>
        </div>
        <CardText className='h4 font-weight-bold text-primary mt-2'>Contact Details</CardText>
        {updateError &&
          <Alert color={'danger'} className='normal-text'>{updateError}</Alert>
        }
        <Row className='mt-3'>
          <Col>
            <Form>
              <FormGroup row>
                <Label for="name" sm={2} md={4} lg={2} className='normal-text text-dark' >Name</Label>
                <Col sm={6}>
                  <Input
                    className="normal-text"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your name..."
                    value={contact.name}
                    onChange={(event) => {handleDetails({name: event.target.value})}}
                    invalid={Boolean(contactErrors.name)}
                  />
                  <FormFeedback className="normal-text">Please provide a name.</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="name" sm={2} md={4} lg={2} className='normal-text text-dark'>Email</Label>
                <Col className="normal-text" sm={6}>
                  <Input
                    className="normal-text"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your work email..."
                    value={contact.email}
                    onChange={(event) => {handleDetails({email: event.target.value})}}
                    invalid={Boolean(contactErrors.email)}
                  />
                  <FormFeedback className="normal-text">Please provide a work email address.</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="phone" sm={2} md={4} lg={2} className='normal-text text-dark'>Phone</Label>
                <Col className="normal-text" sm={6}>
                  <Input
                    className="normal-text"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Your work phone..."
                    value={contact.phone}
                    onChange={(event) => {handleDetails({phone: event.target.value})}}
                    invalid={Boolean(contactErrors.phone)}
                  />
                  <FormFeedback className="normal-text">Please provide a valid phone number.</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup check>
                <Input
                  className="normal-text"
                  type="checkbox"
                  name="consent"
                  id="consent"
                  value={contact.consented}
                  onChange={(event) => {handleDetails({consented: event.target.checked})}}
                  invalid={Boolean(contactErrors.consented)}
                />
                <Label
                  for="consent"
                  check
                ><FormText className="normal-text text-dark">
                  I consent to my data being processed in accordance with hSo's
                    <span className='ml-1 normal-text text-dark'>
                    <a href='https://www.syntura.io/privacypolicy' target="_blank" rel="noopener noreferrer">
                    privacy policy
                  </a>
                  </span> and accept your use of cookies.
                </FormText>
                </Label>
                <FormFeedback className="normal-text ">Please allow us to process your data.</FormFeedback>
              </FormGroup>
              <Button
                size='sm'
                className='normal-text mt-3 main-button tertiary'
                onClick={send}
              >
                Send me a quote
              </Button>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
