import { Alert, Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap'

export const Confirmation = ({
  quoteNumber,
  reset
}) =>{

  return (
    <Card>
      <CardBody>
        <Row className='mt-3'>
          <Col className='d-flex flex-column justify-content-center align-items-center'>
            <p className='h2 text-primary normal-text'>We'll Be In Touch Shortly</p>
          </Col>
        </Row>
        <CardTitle className='h4 normal-text'>Confirmation</CardTitle>
        <Alert className='normal-text' color={'success'}><CardText>Your Quote Number: <span className='normal-text'>{quoteNumber}</span></CardText></Alert>
        <p className='normal-text'>Please make a note of this quote number for your reference as one of our leased line advisors will contact you shortly to discuss your quote in more detail.</p>
        <p className='normal-text'>In the meantime, if you have any questions regarding your quote, please contact us on <span className='normal-text'><a href='tel:02078474510'>020 7847 4510</a></span> or email <span className='normal-text'><a  href='mailto:info@syntura.io'>info@syntura.io</a></span>.</p>
        <p className='text-warning normal-text'>
          Syntura Leased Line Team
        </p>
        <div className='d-flex justify-content-end'>
          <Button className='main-button tertiary' color='dark' size='sm' onClick={reset}>Start a new quote</Button>
        </div>

      </CardBody>
    </Card>
  )
}
