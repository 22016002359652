import { Button, Card, CardBody, CardText, Col, Row } from 'reactstrap'

const speeds = [100,200,500,1000,5000,10000]
export const Bandwidth = ({
  handleSelect,
  address
}) =>{

  return (
    <Card>
      <CardBody>
        <Row className='mt-3'>
          <Col className='d-flex flex-column justify-content-center align-items-center'>
            <p  className='main-text text-dark'>How Much Bandwidth?</p>
            <p className='text-dark normal-text'>How fast do you want your leased line to be?</p>
          </Col>
        </Row>
        <p className='normal-text'>Address: <span className='font-weight-bold text-warning'>{address.label}</span></p>
        <CardText>Bandwidth</CardText>
        <Row>
            {speeds.map((speed) => {
              return (
                <Col md={4} key={`speed-${speed}`}>
                  <Speed speed={speed} key={speed} handleSelect={() => handleSelect(speed)}/>
                </Col>
              )
            })}
        </Row>
      </CardBody>
    </Card>
  )
}

function displayMegabit(speed)
{
  if(speed <= 500){
    return speed +  " Mbit/s";
  }
  return (speed /1000) +  " Gbit/s";
}

const Speed = ({speed, handleSelect}) =>{
  return (
    <Button className='main-button tertiary mb-3' block onClick={handleSelect}>
      <span className='normal-text text-white'>{displayMegabit(speed)}</span>
    </Button>
  )
}
